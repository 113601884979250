require('./bootstrap');

import Alpine from 'alpinejs';
import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";

window.HttpTransportType = HttpTransportType;
window.HubConnectionBuilder = HubConnectionBuilder;
window.Alpine = Alpine;

Alpine.start();

jQuery(document).ready(function($) {
    $('.collapsable_card .trigger').on('click', function() {
        const card = $(this).closest('.collapsable_card');
        const slot = $(card).find('.slot');
        const chevron = $(card).find('.collapse_arrow');

        $(slot).toggleClass('hidden');
        if($(slot).hasClass('hidden')) {
            $(chevron).removeClass('fa-chevron-up');
            $(chevron).addClass('fa-chevron-down');
        } else {
            $(chevron).addClass('fa-chevron-up');
            $(chevron).removeClass('fa-chevron-down');
        }
    });

    $('.mobile-menu-toggler').on('click', function (){
        $('.mobile-menu').toggleClass('mobile-menu--active');
    });
});
